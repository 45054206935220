
.timeline div {
  padding: 0;
  height: 40px;
}
.horizontal  {
  opacity: 100%;
  border-top: 1px dashed #4F4A43;
  margin: 0;
  top: 17px;
  position: relative;
}
.timeline .col-2 {
  display: flex;
  overflow: hidden;
}
.timeline .corner {
  border: 1px dashed #4F4A43;
  width: 100%;
  position: relative;
}

.timeline .top-right {
  left: 50%;
  top: calc(-50% - 2px);
}
.timeline .left-bottom {
  left: -50%;
  top: calc(50% - 3px);
}
.timeline .top-left {
  left: -50%;
  top: calc(-50% - 2px);
}
.timeline .right-bottom {
  left: 50%;
  top: calc(50% - 3px);
}
