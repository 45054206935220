.cursor-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .cursor {
    position: absolute;
    background-color: #ffffff1a;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }