body {
  margin: 0;
  font-family:  monospace, 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020101;
  color:white;
}

.box {
  background-color: #020101;
  list-style: none;
  border: 1px #545454c2 solid;
  font-size: 12pt;
  padding: 12px;
  min-height: 150px !important;
}

.title {
  font-size: 16pt;
  margin: 12px 0;
}

/* Page (markdown data/project/etc) */
.page {
  margin-top: 12px;
  min-height: 650px !important;
}

.page img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  max-width: 50%;
}


/* Directory Path */
.dir-path a{ 
  text-decoration: none !important;
}

.dir-path a:after {
  content: " > ";
}


/* Link Tag */
.link:before {
  content: "[";
}

.link:after {
  content: "]";
}


/* Tags */
.tag {
  background-color: #54545494;
  display: inline-block;
  margin: 3px 6px 6px 0;
  padding: 0 10px;
  border-radius: 12px;
}

.tags:hover {
  cursor: pointer;
}


/* Card */
.xcard {
  min-height: 150px !important;
  max-height: 150px !important;
  overflow:hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s;
}

.xcard:hover {
  cursor: pointer;
  transform: scale(1.025);
}

.xcard #description {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow:hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.xcard article {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}


/* Section Header */
.section-header {
  font-size: 15pt;
  margin: 24px 0 12px 0;
}

.section-header::before {
  content: "<";
}

.section-header::after {
  content: ">";
}

.section-header::after {
  content: ">";
}

.section-footer::before {
  content: "</";
}

.section .box {
  height: 100% !important;
}


/* Navigation */
.navbar-brand {
  color:white;
  user-select: none;
}

.navbar-toggler {
  margin: 0 !important;
  padding: 0 !important;
}

.navbar-collapse > * {
  float: right;
  text-align: end;
}

.footer {
  bottom: 0;
  margin-top: 24px;
  width: 100%;
  padding: 12px;
}


/* Profile Image */
.profile {
  margin: auto;
  width: 128px;
  height: auto;
  overflow: hidden;
  border-radius: 50%;
}

/* Social Icons*/
.socials {
  display: flex; 
  justify-content: end; 
  align-items: center;
}

.socials a {
  margin-left: 12px;
}

.socials img  {
  width: 24px;
  transition: fill 0.3s;
}

/* Misc */
p {
  margin: 0;
  padding: 0;
}

li {
  margin: 12px 0;
}

a:hover {
  color: #a8a8a8 !important;
}

a {
  color: white !important;
  font-weight: bold;
  text-decoration: underline;
}

.page h2 {
  margin-top: 20px;
  font-size: 16pt;
}


.particles-bg-canvas-self {
  position: fixed !important;
}